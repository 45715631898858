import { Box, Button, Flex, Text, Toaster } from "@grupoboticario/flora-react";
import { CheckCircleIcon, TrashIcon } from "@grupoboticario/flora-react-icons";
import {
  Body,
  Card,
  Content,
  Header,
  TeamsContainer,
  Title,
} from "./Teams.styles";
import { Team } from "@shared/types";
import { AttributeValue } from "../Details/Details.styles";
import { useModal } from "@features/TeamMembers/Hooks";
import { ConfirmDialog } from "@shared/components";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useRemoveTeamUser } from "./useRemoveTeamUser";
interface TeamsProps {
  teams: Team[];
}

export function Teams({ teams }: TeamsProps) {
  const { userId } = useParams();
  const { openModal } = useModal();
  const [selectedTeamToRemove, setSelectedTeamToRemove] = useState<Team>(null);
  const { mutate: removeTeamUser } = useRemoveTeamUser();

  const contentModal = (
    <Box>
      <Text>
        Deseja remover o usuário da equipe{" "}
        <strong>{selectedTeamToRemove ? selectedTeamToRemove.name : ""}</strong>
        ?
      </Text>
    </Box>
  );

  function handleOpenRemoveTeamUserModal(team: Team) {
    setSelectedTeamToRemove(team);
    openModal();
  }

  function handleRemoveTeamUser() {
    if (!selectedTeamToRemove) return;
    if (!userId) return;

    removeTeamUser(
      {
        teamId: selectedTeamToRemove.id,
        userId,
      },
      {
        onSuccess() {
          setSelectedTeamToRemove(null);

          Toaster.notify({
            kind: "success",
            description: `O usuário foi removido do time!`,
            origin: "right-top",
            Icon: <CheckCircleIcon />,
          });
        },
        onError() {
          Toaster.notify({
            kind: "error",
            description: "Erro ao remover o usuário do time",
            origin: "right-top",
          });
        },
      },
    );
  }

  return (
    <Flex direction={"column"}>
      {teams.length === 0 ? (
        <Box
          css={{
            padding: "$0 $4",
          }}
        >
          <AttributeValue>
            Não existe equipes em que o usuário é membro.
          </AttributeValue>
        </Box>
      ) : (
        <TeamsContainer>
          {teams?.map((team) => (
            <Card key={team.id}>
              <Flex direction={"column"} gap={"$4"}>
                <Header>
                  <Title>{team.name}</Title>
                </Header>
                <Body>
                  <Title>Responsável</Title>
                  <Flex direction={"column"}>
                    {team.responsibles.length === 0 ? (
                      <Content>--</Content>
                    ) : (
                      team.responsibles.map((responsible) => (
                        <Content key={responsible.id}>
                          {responsible.name}
                        </Content>
                      ))
                    )}
                  </Flex>
                </Body>
              </Flex>
              <Box css={{ verticalAlign: "top" }}>
                <Button
                  data-testid={`remove-team-user-${team.id}`}
                  hierarchy={"tertiary"}
                  onClick={() => handleOpenRemoveTeamUserModal(team)}
                >
                  <TrashIcon color="$nonInteractivePredominant" />
                </Button>
              </Box>
            </Card>
          ))}
          <ConfirmDialog
            title={"Remover usuário da equipe"}
            content={contentModal}
            confirmFn={handleRemoveTeamUser}
          />
        </TeamsContainer>
      )}
    </Flex>
  );
}

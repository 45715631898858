import { useQuery } from "@tanstack/react-query";
import { UserDetails } from "./userDetails.types";
import { fetchUser } from "@api/Users";

export function useUserDetails(userId: string) {
  return useQuery<UserDetails>({
    queryKey: ["user", userId],
    queryFn: () => fetchUser(userId),
  });
}

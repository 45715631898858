import { Button, Flex } from "@grupoboticario/flora-react";
import { CrossIcon } from "@grupoboticario/flora-react-icons";
import {
  ContainerList,
  Card,
  UserName,
  UserEmail,
} from "./NewMemberList.style";
import { Team } from "@shared/types";

export interface UserDataSelected {
  id: string;
  name: string;
  email: string;
  groups: Team[];
}

interface NewMemberListProps {
  newMembers: UserDataSelected[];
  onRemove: (member: UserDataSelected) => void;
}

export function NewMemberList({ newMembers, onRemove }: NewMemberListProps) {
  return (
    <Flex direction={"column"}>
      <ContainerList>
        {newMembers?.map((member) => (
          <Card key={member.email}>
            <Flex justify={"space-between"} align={"center"} gap={"$4"}>
              <Flex direction={"column"} gap={"$1"}>
                <UserName>{member.name}</UserName>
                <UserEmail>{member.email}</UserEmail>
              </Flex>
              <Flex>
                <Button
                  data-testid="remove-new-member"
                  has={"iconOnly"}
                  icon={<CrossIcon />}
                  hierarchy={"tertiary"}
                  onClick={() => onRemove(member)}
                />
              </Flex>
            </Flex>
          </Card>
        ))}
      </ContainerList>
    </Flex>
  );
}

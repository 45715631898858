import { styled } from "@grupoboticario/flora-react";
import { Table as TableFT } from "@grupoboticario/flora-team-react-table";

export const TableRoot = styled(TableFT.Root, {
  borderSpacing: "unset",
  borderCollapse: "unset",

  "& tr > td:first-of-type": {
    borderRadius: "unset",
  },

  "& tr > td:last-of-type": {
    borderRadius: "unset",
  },

  "& th, td": {
    background: "unset",
    borderBottom: "1px solid $nonInteractiveOutline",
    padding: "0 $6",
  },

  "& th:first-child": {
    width: "$16",
  },

  "& th": {
    height: "$16",
    verticalAlign: "middle",
  },

  "& > thead": {
    position: "sticky",
    top: 0,
    background: "$backgroundSecondary",
    zIndex: 1,
  },

  "& > tbody tr:nth-child(odd)": {
    background: "$backgroundPrimary",
  },
}) as typeof TableFT.Root;

export const TableHead = styled(TableFT.Header, {
  background: "$backgroundSecondary",
  span: {
    fontSize: "$bodySmall",
    fontWeight: "$medium",
    textTransform: "capitalize",
  },

  "@mobile": {
    top: 0,
  },
}) as typeof TableFT.Header;

export const TableCell = styled(TableFT.Cell, {
  height: "$14",
  overflow: "hidden",
  span: {
    fontSize: "$bodySmall",
    fontWeight: "$medium",
  },
  a: {
    color: "$primary",
    textDecoration: "none",
  },
}) as typeof TableFT.Cell;

import { Flex, Text } from "@grupoboticario/flora-react";
import { PageTitle } from "../Team/Team.styles";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@grupoboticario/flora-react-icons";
import { UserInfoContainer } from "./User.styles";
import { UserInfo } from "@features/UserInfo";

export function User() {
  return (
    <Flex direction={"column"} css={{ padding: "$6", height: "100%" }}>
      <PageTitle>
        <Link to="/usuarios">
          <ArrowLeftIcon color="$nonInteractivePredominant" />
        </Link>
        <Text>Informações do usuário</Text>
      </PageTitle>
      <UserInfoContainer>
        <UserInfo />
      </UserInfoContainer>
    </Flex>
  );
}

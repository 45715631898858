import { Root } from "./Root";
import { Messages } from "./Messages";
import { Message } from "./Message";
import { Icon } from "./Icon";
import { Actions } from "./Actions";

export const Feedback = {
  Root,
  Messages,
  Message,
  Icon,
  Actions,
};

import {
  Member,
  useModal,
  useMoveMembersToGroup,
  useSelectedMembers,
  useTeamsSelectList,
} from "@features/TeamMembers/Hooks";
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Modal as ModalFlora,
  ModalHeader,
  ModalFooter,
  Box,
  Dropdown,
  DropdownItem,
  Toaster,
} from "@grupoboticario/flora-react";
import { CheckCircleIcon } from "@grupoboticario/flora-react-icons";
import { Team } from "@shared/types";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export interface MoveMembersMutation {
  groupId: string;
  users: Member[];
}

export function Modal() {
  const { teamId } = useParams();

  const { isOpen, closeModal } = useModal();

  const { members, clearAllSelectedMembers } = useSelectedMembers();

  const { data: teams } = useTeamsSelectList();

  const [teamList, setTeamList] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState("");

  const [selectedMembersToMove, setSelectedMembersToMove] =
    useState<MoveMembersMutation>({} as MoveMembersMutation);
  const moveMembersToOtherGroup = useMoveMembersToGroup(
    teamId,
    selectedMembersToMove,
  );

  function handleSelectNewTeamChange(event: ChangeEvent<HTMLInputElement>) {
    const team = event.target.value;

    setSelectedTeam(team);
  }

  function handleMoveMembers() {
    if (!teamId) return;
    if (!selectedTeam) return;
    if (!members || members.length <= 0) return;

    const moveMembers = { groupId: selectedTeam, users: members };

    setSelectedMembersToMove(moveMembers);
    moveMembersToOtherGroup.mutate(undefined, {
      onSuccess: () => {
        closeModal();

        clearAllSelectedMembers();

        Toaster.notify({
          kind: "success",
          description: "Movido com sucesso!",
          origin: "right-top",
          Icon: <CheckCircleIcon />,
        });
      },
      onError: () => {
        Toaster.notify({
          kind: "error",
          description: "Erro ao mover os membros",
          origin: "right-top",
        });
      },
    });
  }

  useEffect(() => {
    function removeTeamIdFromList() {
      if (teams && teams.length > 0) {
        const teamFound = teams.find((team) => team.id === teamId);

        if (!teamFound) {
          return;
        }

        const teamsWithoutTeamId = teams.filter(
          (team) => team.id !== teamFound.id,
        );

        setTeamList(teamsWithoutTeamId);
      }
    }

    removeTeamIdFromList();
  }, [teamId, teams]);

  return (
    <ModalFlora
      css={{
        background: "$backgroundPrimary",
        border: "solid $nonInteractiveAltOutline",
        boxShadow: "$centerMid",
      }}
      open={isOpen}
      onOpenChange={closeModal}
    >
      <ModalContent
        size="standard"
        css={{ padding: "$8", borderRadius: "$medium" }}
      >
        <ModalCloseButton />
        <ModalHeader
          css={{
            fontSize: "$subtitleDesk",
            fontWeight: "$regular",
            lineHeight: "$subtitleDesk",
            color: "$nonInteractivePredominant",
          }}
        >
          Selecione a equipe de destino
        </ModalHeader>
        <ModalBody>
          <Box>
            <Dropdown
              id={"teams"}
              labelText="Equipe"
              optionalText=""
              as="form"
              required
              invalid={!selectedTeam}
              invalidText="Campo obrigatório"
              value={selectedTeam}
              onChange={handleSelectNewTeamChange}
            >
              {teamList?.map((teamData) => (
                <DropdownItem key={teamData.id} value={teamData.id}>
                  {teamData.name}
                </DropdownItem>
              ))}
            </Dropdown>
          </Box>
        </ModalBody>
        <ModalFooter
          css={{ display: "flex", flexDirection: "column", gap: "$3" }}
        >
          <Button
            disabled={!selectedTeam}
            onClick={handleMoveMembers}
            isLoading={moveMembersToOtherGroup.isPending}
          >
            Mover
          </Button>
          <ModalCloseButton asChild>
            <Button hierarchy={"secondary"} styleSemantic={"neutral"}>
              Cancelar
            </Button>
          </ModalCloseButton>
        </ModalFooter>
      </ModalContent>
    </ModalFlora>
  );
}

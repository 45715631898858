import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { fetchTeamMembers } from "@api/TeamMembers";

export function useTeamMembers(teamId: string, pageCursor?: string) {
  return useQuery({
    queryKey: ["team", teamId, "members", pageCursor],
    queryFn: () => fetchTeamMembers(teamId, pageCursor),
    placeholderData: keepPreviousData,
  });
}

import { useMutation } from "@tanstack/react-query";
import { addMemberToGroup } from "@api/Users";
import { queryClient } from "../../services/reactQuery";

export function useAddMemberToGroup(teamId: string) {
  return useMutation({
    mutationFn: (userId: string) => addMemberToGroup(teamId, userId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["team", teamId, "members"],
      });
    },
  });
}

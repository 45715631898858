import { useQuery } from "@tanstack/react-query";
import { fetchTeams } from "@api/Teams";
import { Team } from "@shared/types";

export function useTeamsSelectList() {
  return useQuery<Team[]>({
    queryKey: ["select", "teams"],
    queryFn: () => fetchTeams(),
  });
}

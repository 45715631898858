import { addMemberToGroup } from "@api/Users";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../../services/reactQuery";
import { UserDetails } from "../Details/userDetails.types";
import { Team } from "@shared/types";

interface AddUserToTeamProps {
  team: Team;
  userId: string;
}

export function useAddUserToTeam() {
  return useMutation({
    mutationFn: ({ team, userId }: AddUserToTeamProps) =>
      addMemberToGroup(team.id, userId),
    onSuccess: (_data, variables) => {
      const { userId, team } = variables;
      queryClient.setQueryData(["user", userId], (oldData: UserDetails) => {
        return {
          ...oldData,
          groups: [...oldData.groups, team],
        };
      });
    },
  });
}

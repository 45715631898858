import { authService } from "@vdi/auth-service";

export async function addMemberToGroup(teamId: string, userId: string) {
  const basUrlWithoutAdminPrefix = process.env.API_BASE_URL.split("/admin")[0];

  const { token } = await authService.getTokenOrRedirect();
  const url = `${basUrlWithoutAdminPrefix}/groups/${teamId}/user/${userId}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "PATCH",
  });

  if (!response.ok) {
    throw new Error(`request response status error: ${response.status}`);
  }
}

import { useRef } from "react";

interface UseDebounceProps {
  Fn: (...args: unknown[]) => void;
  delay: number;
}

export function useDebounce({ Fn, delay }: UseDebounceProps) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  function debouncedFn(...args: unknown[]) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      Fn(...args);
    }, delay);
  }
  return debouncedFn;
}

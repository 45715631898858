import { Box, Button, Flex } from "@grupoboticario/flora-react";
import { PlusIcon } from "@grupoboticario/flora-react-icons";
import { Search } from "@shared/components/Search";
import { navigateToUrl } from "single-spa";

export function ActionBar() {
  return (
    <Flex justify={"space-between"} gap={"$4"} css={{ flexWrap: "wrap" }}>
      <Box css={{ width: "22.5rem" }}>
        <Search placeholder="buscar usuários" isLoading={false} />
      </Box>
      <Box>
        <Button
          has="iconLeft"
          icon={<PlusIcon />}
          onClick={() => {
            navigateToUrl("/admin-users/usuarios/criar");
          }}
        >
          Criar usuários
        </Button>
      </Box>
    </Flex>
  );
}

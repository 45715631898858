import { authService } from "@vdi/auth-service";

export async function fetchTeam(teamId: string) {
  const { token } = await authService.getTokenOrRedirect();
  const url = `${process.env.API_BASE_URL}/groups/${teamId}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`request response status error: ${response.status}`);
  }
  return response.json();
}

import { Text } from "@grupoboticario/flora-react";

import { Feedback } from "@shared/components/Feedback";
import { FeedbackProps } from "@shared/types";

interface LoadingProps extends FeedbackProps {}

export function Loading({ title, subtitle }: LoadingProps) {
  return (
    <Feedback.Root isLoading>
      <Feedback.Messages>
        <Feedback.Message>
          <Text
            css={{
              fontWeight: "$medium",
              fontSize: "$bodyLarge",
              lineHeight: "$short",
            }}
          >
            {title}
          </Text>
        </Feedback.Message>
        {subtitle && (
          <Feedback.Message>
            <Text
              css={{
                fontSize: "$bodySmall",
                fontWeight: "$medium",
                color: "$nonInteractiveAuxiliar",
                lineHeight: "$short",
              }}
            >
              {subtitle}
            </Text>
          </Feedback.Message>
        )}
      </Feedback.Messages>
    </Feedback.Root>
  );
}

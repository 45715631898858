import { Box, Card, styled, Text } from "@grupoboticario/flora-react";

export const TeamNameContainer = styled(Box, {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}) as typeof Box;

export const TeamRoleContainer = styled(Box, {
  display: "flex",
  flexDirection: "column",
  gap: "$6",
  "@desktop": {
    flexDirection: "row",
  },
}) as typeof Box;

export const CardInfo = styled(Box, {
  display: "flex",
  flexDirection: "column",
  height: "6.5rem",
  "@desktop": {
    width: "50%",
  },
}) as typeof Box;

export const CardBox = styled(Card, {
  display: "flex",
  flex: "auto",
  alignItems: "center",
  gap: "$4",
  padding: "$4",
}) as typeof Card;

export const BoxContent = styled(Box, {
  display: "flex",
  flexDirection: "column",
}) as typeof Box;

export const Avatar = styled(Box, {
  width: "$12",
  height: "$12",
  borderRadius: "$pill",
  backgroundColor: "$nonInteractiveAltAuxiliar",
}) as typeof Box;

export const TextRoleLabel = styled(Text, {
  fontSize: "$bodySmall",
  fontWeight: "$medium",
  lineHeight: "$tall",
}) as typeof Text;

export const TextRoleEmail = TextRoleLabel;

export const TextRole = styled(Text, {
  fontSize: "$bodyLarge",
  fontWeight: "$medium",
  lineHeight: "$tall",
}) as typeof Text;

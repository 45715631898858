import { fetchUsers } from "@api/Users";
import { useQuery } from "@tanstack/react-query";

export function useSearchUser(userTerm: string) {
  return useQuery({
    queryKey: ["user", userTerm],
    queryFn: () => fetchUsers(userTerm),
    enabled: !!userTerm,
    staleTime: 1000 * 60,
  });
}

import { Box, Text } from "@grupoboticario/flora-react";
import { TeamMembersList } from "./Components/TeamMembersList";
import { useParams } from "react-router-dom";
import { ActionBar } from "./Components/ActionBar";
import { ModalProvider, SelectedMembersProvider } from "./Hooks";
import { Modal } from "./Components/Modal";

export function TeamMembers() {
  const { teamId } = useParams();

  return (
    <>
      <Box>
        <Text
          css={{
            fontWeight: "$medium",
            fontSize: "$bodySmall",
            lineHeight: "$bodySmallStandard",
          }}
        >
          Integrantes
        </Text>
      </Box>
      <SelectedMembersProvider>
        <ModalProvider>
          <ActionBar teamId={teamId} />
          <TeamMembersList teamId={teamId} />
          <Modal />
        </ModalProvider>
      </SelectedMembersProvider>
    </>
  );
}

import { useModal, useSelectedMembers } from "@features/TeamMembers/Hooks";
import { Button, Flex } from "@grupoboticario/flora-react";
import { PlusIcon } from "@grupoboticario/flora-react-icons";
import { Link } from "react-router-dom";

interface ActionBarProps {
  teamId: string;
}

export function ActionBar({ teamId }: Readonly<ActionBarProps>) {
  const { members } = useSelectedMembers();
  const { openModal } = useModal();

  const isSelectedMembers = members.length > 0;

  return (
    <Flex justify={"flex-end"} gap={"$4"}>
      <Button
        disabled={!isSelectedMembers}
        hierarchy="secondary"
        onClick={openModal}
      >
        Mover integrantes
      </Button>
      <Button
        as={Link}
        has="iconLeft"
        icon={<PlusIcon />}
        to={`/equipes/${teamId}/integrantes/cadastrar`}
      >
        Adicionar integrantes
      </Button>
    </Flex>
  );
}

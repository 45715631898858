import {
  Grid,
  styled,
  Card as CardFlora,
  Text,
  Flex,
} from "@grupoboticario/flora-react";

export const TeamsContainer = styled(Grid, {
  gridTemplateColumns: "repeat(auto-fill, minmax(24rem, 1fr))",
  gap: "$4",
  maxHeight: "37.5rem",
  overflow: "auto",
}) as typeof Grid;

export const Card = styled(CardFlora, {
  padding: "$4",
  display: "flex",
  justifyContent: "space-between",
  gap: "$4",
}) as typeof Card;

export const Header = styled(Flex, {
  justifyContent: "space-between",
  alignItems: "center",
  gap: "$4",
}) as typeof Flex;

export const Title = styled(Text, {
  fontSize: "$bodySmall",
  fontWeight: "$medium",
  lineHeight: "$bodySmallShort",
}) as typeof Text;

export const Body = styled(Flex, {
  flexDirection: "column",
  gap: "$2",
}) as typeof Flex;

export const Content = styled(Text, {
  fontSize: "$exceptionsAuxiliar",
  fontWeight: "$medium",
  lineHeight: "$exceptionsAuxiliar",
}) as typeof Text;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-in {
    animation: fadein 1s;
    -moz-animation: fadein 1s; /* Firefox */
    -webkit-animation: fadein 1s; /* Safari and Chrome */
    -o-animation: fadein 1s; /* Opera */
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,yBAAyB,EAAE,YAAY;IACvC,4BAA4B,EAAE,sBAAsB;IACpD,uBAAuB,EAAE,UAAU;AACvC;;AAEA;IACI;QACI,SAAS;IACb;IACA;QACI,SAAS;IACb;AACJ;AACA,yBAAyB,YAAY;IACjC;QACI,SAAS;IACb;IACA;QACI,SAAS;IACb;AACJ;AACA,4BAA4B,sBAAsB;IAC9C;QACI,SAAS;IACb;IACA;QACI,SAAS;IACb;AACJ;AACA,uBAAuB,UAAU;IAC7B;QACI,SAAS;IACb;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".fade-in {\n    animation: fadein 1s;\n    -moz-animation: fadein 1s; /* Firefox */\n    -webkit-animation: fadein 1s; /* Safari and Chrome */\n    -o-animation: fadein 1s; /* Opera */\n}\n\n@keyframes fadein {\n    from {\n        opacity:0;\n    }\n    to {\n        opacity:1;\n    }\n}\n@-moz-keyframes fadein { /* Firefox */\n    from {\n        opacity:0;\n    }\n    to {\n        opacity:1;\n    }\n}\n@-webkit-keyframes fadein { /* Safari and Chrome */\n    from {\n        opacity:0;\n    }\n    to {\n        opacity:1;\n    }\n}\n@-o-keyframes fadein { /* Opera */\n    from {\n        opacity:0;\n    }\n    to {\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

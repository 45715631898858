import { ReactNode, createContext, useContext, useState } from "react";

export interface Member {
  id: string;
}

interface SelectedMembersContextData {
  members: Member[];
  selectMembers: (members: Member) => void;
  clearAllSelectedMembers: () => void;
}

interface SelectedMembersProviderProps {
  children: ReactNode;
}

export const SelectedMembersContext = createContext<SelectedMembersContextData>(
  {} as SelectedMembersContextData,
);

export function SelectedMembersProvider({
  children,
}: SelectedMembersProviderProps) {
  const [members, setMembers] = useState<Member[]>([]);

  function selectMembers(selectedMember: Member) {
    const memberAlreadySelected = members.find(
      (member) => member.id === selectedMember.id,
    );

    if (memberAlreadySelected) {
      const newMembers = members.filter(
        (member) => member.id !== selectedMember.id,
      );
      return setMembers(newMembers);
    }

    return setMembers([...members, selectedMember]);
  }

  function clearAllSelectedMembers() {
    setMembers([]);
  }

  return (
    <SelectedMembersContext.Provider
      value={{ members, selectMembers, clearAllSelectedMembers }}
    >
      {children}
    </SelectedMembersContext.Provider>
  );
}

export function useSelectedMembers() {
  return useContext(SelectedMembersContext);
}

import { ReactNode } from "react";
import { Text } from "@grupoboticario/flora-react";

import { FeedbackProps } from "@shared/types";
import { Feedback } from "../Feedback";

interface FeedbackMessageProps extends FeedbackProps {
  icon?: ReactNode;
  button?: ReactNode;
}

export function FeedbackMessage({
  title,
  subtitle,
  icon,
  button,
}: FeedbackMessageProps) {
  return (
    <Feedback.Root className="fade-in">
      {icon && <Feedback.Icon icon={icon} />}
      <Feedback.Messages>
        <Text
          css={{
            fontWeight: "$medium",
            fontSize: "$bodyLarge",
            lineHeight: "$short",
          }}
        >
          {title}
        </Text>
        {subtitle && (
          <Feedback.Message>
            <Text
              css={{
                fontSize: "$bodySmall",
                fontWeight: "$medium",
                color: "$nonInteractiveAuxiliar",
                lineHeight: "$short",
              }}
            >
              {subtitle}
            </Text>
          </Feedback.Message>
        )}
      </Feedback.Messages>
      {button && <Feedback.Actions>{button}</Feedback.Actions>}
    </Feedback.Root>
  );
}

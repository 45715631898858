import { Flex, Text } from "@grupoboticario/flora-react";
import { PageTitle, TeamContainer } from "../Team/Team.styles";
import { TeamList } from "@features/TeamList";

export function Teams() {
  return (
    <Flex direction={"column"} css={{ margin: "$6" }}>
      <PageTitle>
        <Text>Equipes</Text>
      </PageTitle>
      <TeamContainer>
        <TeamList />
      </TeamContainer>
    </Flex>
  );
}

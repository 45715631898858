import {
  Flex,
  Switch,
  SwitchProps,
  Toaster,
  Text,
  Button,
} from "@grupoboticario/flora-react";
import { useUserUpdateStatus } from "@features/UserList/hooks";
import { CheckCircleIcon } from "@grupoboticario/flora-react-icons";
import { authService } from "@vdi/auth-service";

interface UserStatusSwitchProps extends Omit<SwitchProps, "defaultValue"> {
  isActive: boolean;
  userId: string;
}

export function UserStatusSwitch({ isActive, userId }: UserStatusSwitchProps) {
  const { mutate: updateUserStatus, isPending } = useUserUpdateStatus();

  function handleChangeStatus(checked: boolean) {
    if (authService.userData.id === userId) {
      return;
    }

    updateUserStatus(
      { isActive: checked, userId: userId },
      {
        onSuccess: () => {
          Toaster.notify({
            kind: "success",
            description: `Usuário ${checked ? "ativado" : "desativado"} com sucesso!`,
            origin: "right-top",
            Icon: <CheckCircleIcon />,
          });
        },
        onError: () => {
          Toaster.notify({
            kind: "error",
            description: `Erro ao ${checked ? "ativar" : "desativar"} o usuário`,
            origin: "right-top",
          });
        },
      },
    );
  }

  return (
    <Flex align="center">
      <Switch
        onCheckedChange={handleChangeStatus}
        showTexts={false}
        defaultChecked={isActive}
        disabled={authService.userData.id == userId || isPending}
        checked={isActive}
      />
      <Text>{isActive ? "Ativo" : "Inativo"}</Text>
      {isPending && (
        <Button
          data-testid="loading-switch-on-mutate"
          isLoading
          size="small"
          hierarchy={"tertiary"}
        />
      )}
    </Flex>
  );
}

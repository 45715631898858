import { PaginateCursorParams } from "@shared/types";
import { authService } from "@vdi/auth-service";

export async function fetchUsers(
  term?: string,
  paginateParams?: PaginateCursorParams,
) {
  const { token } = await authService.getTokenOrRedirect();

  const { cursor, direction, order, perPage } = paginateParams || {};

  const perPageValue = perPage || process.env.USER_LIST_PAGINATION_PAGE_LIMIT;
  const searchParams = new URLSearchParams();

  if (term) {
    searchParams.append("filter", term);
  }
  if (cursor) {
    searchParams.append("value", cursor);
  }
  if (direction) {
    searchParams.append("direction", direction);
  }
  if (order) {
    searchParams.append("order", order);
  }
  searchParams.append("perPage", `${perPageValue}`);

  const url = `${process.env.API_BASE_URL}/users?${searchParams}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`request response status error: ${response.status}`);
  }
  return response.json();
}

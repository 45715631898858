import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../../services/reactQuery";
import { UserDetails } from "../Details/userDetails.types";
import { removeTeamMember } from "@api/TeamMembers";

interface RemoveTeamUserProps {
  teamId: string;
  userId: string;
}

export function useRemoveTeamUser() {
  return useMutation({
    mutationFn: ({ teamId, userId }: RemoveTeamUserProps) =>
      removeTeamMember(teamId, userId),
    onSuccess: (_data, variables) => {
      const { userId, teamId } = variables;
      queryClient.setQueryData(["user", userId], (oldData: UserDetails) => {
        const removeDeletedGroup = oldData.groups.filter(
          (group) => group.id !== teamId,
        );
        return {
          ...oldData,
          groups: [...removeDeletedGroup],
        };
      });
    },
  });
}

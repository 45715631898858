import { Box } from "@grupoboticario/flora-react";
import { Search as SearchFlora } from "@grupoboticario/flora-team-react-search";
import { useSearch } from "./useSearch";
import { useDebounce } from "@shared/hooks";
import { useState } from "react";

interface SearchProps {
  placeholder: string;
  onSelectOption?: (option: string) => void;
  options?: string[];
  isLoading: boolean;
}

export function Search({
  placeholder,
  onSelectOption,
  options,
  isLoading,
}: SearchProps) {
  const { searchTerm, setSearchTerm } = useSearch();
  const [inputType, setInputType] = useState<string>("");

  const debouncedSearch = useDebounce({ Fn: setSearchTerm, delay: 500 });

  function handleSearchChange(term: string) {
    setInputType(term);
    debouncedSearch(term);
  }

  return (
    <>
      <Box data-testid="search-component">
        <SearchFlora.Root>
          <SearchFlora.Trigger>
            <SearchFlora.Input
              placeholder={placeholder}
              value={inputType}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
          </SearchFlora.Trigger>
          {options && (
            <SearchFlora.Content
              open={searchTerm ? true : false}
              loading={isLoading}
              onClose={() => handleSearchChange("")}
              onSelect={(option) => onSelectOption(option)}
              options={options}
              value={searchTerm}
            />
          )}
        </SearchFlora.Root>
      </Box>
    </>
  );
}

import { Text, Flex, Button, Toaster } from "@grupoboticario/flora-react";
import { Search } from "@shared/components/Search";
import { useEffect, useState } from "react";
import { useSearchUser } from "./useSearchUser";
import {
  NewMemberList,
  UserDataSelected,
  useAddMemberToGroup,
} from "@features/NewMemberList";
import { useNavigate, useParams } from "react-router-dom";
import { CheckCircleIcon } from "@grupoboticario/flora-react-icons";
import { useSearch } from "@shared/components/Search/useSearch";

export function NewMember() {
  const { teamId } = useParams();
  const navigate = useNavigate();
  const { searchTerm } = useSearch();

  const [options, setOptions] = useState<string[]>([]);

  const [selectedUsers, setSelectedUsers] = useState<UserDataSelected[]>([]);

  const { data: searchUserData, isLoading } = useSearchUser(searchTerm);

  const addMemberToGroup = useAddMemberToGroup(teamId);

  function handleSelectOption(option: string) {
    if (!option) {
      return;
    }

    const email = option.split(" - ")[1];

    const userFound = searchUserData.data.find((user) => user.email === email);
    const newMember = userFound;
    setSelectedUsers([...selectedUsers, newMember]);
  }

  function handleRemoveNewMemberFromList(member: UserDataSelected) {
    const newMembers = selectedUsers.filter(
      (selectedMember) => selectedMember.id !== member.id,
    );

    setSelectedUsers(newMembers);
  }

  function handleAddMembers() {
    if (!teamId) return;
    if (!selectedUsers || selectedUsers.length <= 0) return;

    try {
      selectedUsers.forEach(async (user) => {
        const { id } = user;
        await addMemberToGroup.mutateAsync(id, {
          onSuccess() {
            Toaster.notify({
              kind: "success",
              description: `O usuário ${user.email} foi adicionado a equipe!`,
              origin: "right-top",
              Icon: <CheckCircleIcon />,
            });
          },
          onError() {
            Toaster.notify({
              kind: "error",
              description: `Erro ao adicionar o usuário ${user.email}`,
              origin: "right-top",
            });
          },
        });
      });

      setTimeout(() => {
        navigate(`/equipes/${teamId}`);
      }, 5000);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    function setDataOptions() {
      if (searchUserData?.data?.length > 0) {
        const newOptions = searchUserData.data.map(
          (option) => `${option.name} - ${option.email}`,
        );

        setOptions(newOptions);
      }
    }

    setDataOptions();
  }, [searchUserData]);

  return (
    <Flex
      direction={"column"}
      css={{
        gap: "$6",
        margin: "$6",
      }}
    >
      <Flex direction={"column"} justify={"center"} align={"center"}>
        <Text css={{ fontSize: "$subtitleDesk", fontWeight: "$medium" }}>
          Adicionar Integrantes
        </Text>
      </Flex>
      <Flex direction={"column"} justify={"center"} align={"center"} gap={"$4"}>
        <Flex direction={"column"} gap={"$4"} css={{ maxWidth: "50rem" }}>
          <Text
            css={{
              fontSize: "$bodyLarge",
              fontWeight: "$medium",
              lineHeight: "$short",
            }}
          >
            Integrantes
          </Text>
          <Text
            css={{
              fontSize: "$bodySmall",
              fontWeight: "$medium",
              lineHeight: "$bodySmallStandard",
            }}
          >
            Digite o e-mail do integrantes e selecione-o na lista suspensa a ser
            exibida
          </Text>
          <Search
            placeholder="Adicionar integrantes"
            onSelectOption={handleSelectOption}
            options={options}
            isLoading={isLoading}
          />
          <NewMemberList
            newMembers={selectedUsers}
            onRemove={handleRemoveNewMemberFromList}
          />
        </Flex>
        <Flex gap={"$4"}>
          <Button
            hierarchy={"secondary"}
            css={{ padding: "$4 $22" }}
            onClick={() => navigate(-1)}
          >
            Cancelar
          </Button>
          <Button
            hierarchy={"primary"}
            disabled={selectedUsers.length === 0}
            css={{ padding: "$4 $22" }}
            onClick={() => handleAddMembers()}
          >
            Salvar
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

import { userUpdateStatus } from "@api/Users";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../services/reactQuery";

export function useUserUpdateStatus() {
  return useMutation({
    mutationFn: ({ isActive, userId }: { isActive: boolean; userId: string }) =>
      userUpdateStatus(isActive, userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
}

import { useModal } from "@features/TeamMembers/Hooks";
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Modal as ModalFlora,
  ModalFooter,
  ModalHeader,
} from "@grupoboticario/flora-react";
import { ReactNode } from "react";

interface ModalProps {
  title: string;
  content: ReactNode;
  confirmFn: () => void;
}

export function ConfirmDialog({ title, content, confirmFn }: ModalProps) {
  const { isOpen, closeModal } = useModal();

  function handleConfirm() {
    confirmFn();
    closeModal();
  }

  return (
    <ModalFlora
      css={{
        background: "$backgroundPrimary",
        border: "solid $nonInteractiveAltOutline",
        boxShadow: "$centerMid",
      }}
      open={isOpen}
      onOpenChange={closeModal}
    >
      <ModalContent
        size="standard"
        css={{ padding: "$8", borderRadius: "$medium" }}
      >
        <ModalCloseButton />
        <ModalHeader
          css={{
            fontSize: "$subtitleDesk",
            fontWeight: "$regular",
            lineHeight: "$subtitleDesk",
            color: "$nonInteractivePredominant",
          }}
        >
          {title}
        </ModalHeader>
        <ModalBody>{content}</ModalBody>
        <ModalFooter css={{ display: "flex", gap: "$3" }}>
          <Button onClick={() => handleConfirm()}>Confirmar</Button>
          <ModalCloseButton asChild>
            <Button hierarchy={"secondary"} styleSemantic={"neutral"}>
              Cancelar
            </Button>
          </ModalCloseButton>
        </ModalFooter>
      </ModalContent>
    </ModalFlora>
  );
}

import {
  Card as CardFlora,
  Grid,
  Text,
  styled,
} from "@grupoboticario/flora-react";

export const ContainerList = styled(Grid, {
  gridTemplateColumns: "repeat(1, minmax(24rem, 1fr))",
  gap: "$4",
  "@desktop": {
    gridTemplateColumns: "repeat(2, minmax(24rem, 1fr))",
  },
  maxHeight: "37.5rem",
  overflow: "auto",
}) as typeof Grid;

export const Card = styled(CardFlora, {
  padding: "$4",
}) as typeof Card;

export const UserName = styled(Text, {
  fontSize: "$bodySmall",
  fontWeight: "$medium",
  lineHeight: "$bodySmallShort",
}) as typeof Text;

export const UserEmail = styled(Text, {
  fontSize: "$exceptionsAuxiliar",
  fontWeight: "$medium",
  lineHeight: "$exceptionsAuxiliar",
}) as typeof Text;

import { Flex } from "@grupoboticario/flora-react";
import { Table } from "@grupoboticario/flora-team-react-table";
import {
  StyledCheckbox,
  TableCell,
  TableHead,
  TableRoot,
} from "./TeamMembersList.style";
import { Paginate } from "@shared/components";
import { useEffect, useState } from "react";
import {
  type Member,
  useSelectedMembers,
  useTeamMembers,
} from "@features/TeamMembers/Hooks";

interface TeamMembersListProps {
  teamId: string;
}

export function TeamMembersList({ teamId }: TeamMembersListProps) {
  const [nextPage, setNextPage] = useState<string | undefined>("");
  const [previousPage, setPreviousPage] = useState<string | undefined>("");

  const [pageCursor, setPageCursor] = useState<string | undefined>("");

  const {
    data: membersData,
    isPlaceholderData,
    isError,
    isLoading,
    isRefetching,
  } = useTeamMembers(teamId, pageCursor);

  const { selectMembers } = useSelectedMembers();

  function handleSelectMembers(member: Member) {
    const selectedMember: Member = {
      id: member.id,
    };
    selectMembers(selectedMember);
  }

  useEffect(() => {
    setNextPage(membersData?.meta?.next);
    setPreviousPage(membersData?.meta?.previous);
  }, [membersData]);

  function hasMoreThanOnePage() {
    return !(!nextPage && !previousPage);
  }

  if (isLoading) {
    return <div>Carregando membros do time...</div>;
  }

  if (isRefetching) {
    return <div>Atualizando a lista de membros do time...</div>;
  }

  if (isError) {
    return <div>Erro ao carregar membros do time</div>;
  }
  return (
    <Flex
      css={{ flexDirection: "column", alignItems: "center", gap: "$6" }}
      data-testid="team-members"
    >
      <TableRoot>
        <thead>
          <Table.Row>
            <TableHead></TableHead>
            <TableHead>Nome</TableHead>
            <TableHead align="left">E-mail</TableHead>
            <TableHead align="left">Data de cadastro</TableHead>
          </Table.Row>
        </thead>
        <tbody>
          {membersData?.items.map((member) => (
            <Table.Row key={member.id}>
              <TableCell align="left" asChild>
                <StyledCheckbox
                  id={`${member.id}`}
                  onChange={() => handleSelectMembers(member)}
                />
              </TableCell>
              <TableCell align="left">{member.name}</TableCell>
              <TableCell align="left">{member.email}</TableCell>
              <TableCell align="left">
                {new Intl.DateTimeFormat("pt-BR").format(
                  new Date(member.createdAt),
                )}
              </TableCell>
            </Table.Row>
          ))}
        </tbody>
      </TableRoot>
      {hasMoreThanOnePage() && (
        <Paginate
          nextPageDisabled={isPlaceholderData || !nextPage}
          previousPageDisabled={!previousPage}
          meta={{
            next: () => setPageCursor(nextPage),
            previous: () => setPageCursor(previousPage),
          }}
        />
      )}
    </Flex>
  );
}

import { MoveMembersMutation } from "@features/TeamMembers/Components/Modal";
import { authService } from "@vdi/auth-service";

export async function moveMembersToGroup(
  teamId: string,
  membersToGroup: MoveMembersMutation,
) {
  const { token } = await authService.getTokenOrRedirect();
  const url = `${process.env.API_BASE_URL}/groups/${teamId}/users`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    method: "PUT",
    body: JSON.stringify(membersToGroup),
  });

  if (!response.ok) {
    throw new Error(`request response status error: ${response.status}`);
  }
}

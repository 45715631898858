import { fetchUsers } from "@api/Users";
import type { PaginateCursorParams } from "@shared/types";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import type { UserList } from "./user.types";

export function useUserList(
  term?: string,
  { cursor = "", direction = "next" }: PaginateCursorParams = {},
) {
  return useQuery<UserList>({
    queryKey: ["users", term, cursor, direction],
    queryFn: () => fetchUsers(term, { cursor, direction }),
    placeholderData: keepPreviousData,
  });
}

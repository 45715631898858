import { Box, Flex } from "@grupoboticario/flora-react";
import {
  AttributeBox,
  AttributeLabel,
  AttributeValue,
  Status,
} from "./Details.styles";
import { useMemo } from "react";
import { UserDetails } from "./userDetails.types";

interface DetailsProps {
  user: UserDetails;
}
export function Details({ user }: DetailsProps) {
  const dateFormatter = useMemo(() => new Intl.DateTimeFormat("pt-BR"), []);
  const dateTime = useMemo(
    () =>
      new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: "short",
      }),
    [],
  );

  return (
    <>
      <Flex id="name-id-section-content-row" gap={"$1"}>
        <AttributeBox id="name" css={{ width: "70%" }}>
          <Box>
            <AttributeLabel>Nome</AttributeLabel>
          </Box>
          <Box>
            <AttributeValue>{user.name}</AttributeValue>
          </Box>
        </AttributeBox>
        <AttributeBox css={{ width: "30%" }}>
          <Box>
            <AttributeLabel>ID</AttributeLabel>
          </Box>
          <Box>
            <AttributeValue>{user.id}</AttributeValue>
          </Box>
        </AttributeBox>
      </Flex>

      <Flex id="identity-section" direction={"column"} gap={"$3"}>
        <Box
          id="identity-section-header"
          css={{
            padding: "$0 $4",
          }}
        >
          <AttributeLabel>Identificação</AttributeLabel>
        </Box>
        <Flex id="identity-section-content" direction={"column"} gap={"$1"}>
          <Flex className="identity-section-content-row" gap={"$1"}>
            <AttributeBox css={{ width: "50%" }}>
              <Box>
                <AttributeLabel>E-mail</AttributeLabel>
              </Box>
              <Box>
                <AttributeValue>{user.email}</AttributeValue>
              </Box>
            </AttributeBox>

            <AttributeBox css={{ width: "30%" }}>
              <Box>
                <AttributeLabel>Código VD+</AttributeLabel>
              </Box>
              <Box>
                <AttributeValue>
                  {!user.geraId ? "--" : user.geraId}
                </AttributeValue>
              </Box>
            </AttributeBox>

            <AttributeBox css={{ width: "20%" }}>
              <Box>
                <AttributeLabel>Status</AttributeLabel>
              </Box>
              <Box>
                <Status active={user.active}>
                  {user.active ? "Ativo" : "Inativo"}
                </Status>
              </Box>
            </AttributeBox>
          </Flex>

          <Flex className="identity-section-content-row" gap={"$1"}>
            <AttributeBox css={{ width: "50%" }}>
              <Box>
                <AttributeLabel>Data de criação</AttributeLabel>
              </Box>
              <Box>
                <AttributeValue>
                  {user.createdAt
                    ? dateFormatter.format(new Date(user.createdAt))
                    : "--"}
                </AttributeValue>
              </Box>
            </AttributeBox>

            <AttributeBox css={{ width: "50%" }}>
              <Box>
                <AttributeLabel>Último acesso</AttributeLabel>
              </Box>
              <Box>
                <AttributeValue>
                  {user.lastLoginAt
                    ? dateTime.format(new Date(user.lastLoginAt))
                    : "--"}
                </AttributeValue>
              </Box>
            </AttributeBox>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

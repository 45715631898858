import { authService } from "@vdi/auth-service";

export async function userUpdateStatus(isActive: boolean, userId: string) {
  const basUrlWithoutAdminPrefix = process.env.API_BASE_URL.split("/admin")[0];

  const { token } = await authService.getTokenOrRedirect();
  const url = `${basUrlWithoutAdminPrefix}/users/status/${userId}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    method: "PATCH",
    body: JSON.stringify({ active: isActive }),
  });

  if (!response.ok) {
    throw new Error(`request response status error: ${response.status}`);
  }
}

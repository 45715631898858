import { FeedbackMessage, Loading } from "@shared/components";
import { Details } from "./components/Details";
import { useUserDetails } from "./components/Details/useUserDetails";
import { CrossCircleIcon, PlusIcon } from "@grupoboticario/flora-react-icons";
import { Button, Flex } from "@grupoboticario/flora-react";
import { useParams } from "react-router-dom";
import { AttributeLabel } from "./components/Details/Details.styles";
import { useState } from "react";
import { Teams } from "./components/Teams";
import { AddTeamModal } from "./components/AddTeamModal";
import { ModalProvider } from "@features/TeamMembers/Hooks";

export function UserInfo() {
  const { userId } = useParams();
  const {
    data: user,
    isError,
    isLoading,
    refetch: reloadUserDetails,
  } = useUserDetails(userId);
  const [isOpenModal, setIsOpenModal] = useState(false);

  function handleAddTeam() {
    setIsOpenModal(true);
  }

  if (isLoading) {
    return (
      <Loading
        title="Carregando as informações"
        subtitle="Por favor, aguarde um instante."
      />
    );
  }

  if (isError) {
    return (
      <FeedbackMessage
        icon={<CrossCircleIcon color="$nonInteractiveAuxiliar" size={40} />}
        title="Falha ao carregar as informações do usuário"
        button={
          <Button onClick={() => reloadUserDetails()}>Tentar novamente</Button>
        }
      />
    );
  }

  return (
    <Flex direction={"column"} gap={"$6"} className="fade-in">
      <Details user={user} />
      <Flex id="teams-section" direction={"column"} gap={"$3"}>
        <Flex
          id="teams-section-header"
          justify={"space-between"}
          align={"center"}
          css={{
            padding: "$0 $4",
          }}
        >
          <AttributeLabel>Equipes que é membro</AttributeLabel>
          <Button has="iconLeft" icon={<PlusIcon />} onClick={handleAddTeam}>
            Adicionar equipe
          </Button>
        </Flex>
        <ModalProvider>
          <Teams teams={user.groups} />
        </ModalProvider>
      </Flex>
      <AddTeamModal
        title="Selecione uma equipe"
        isOpen={isOpenModal}
        teamsUserIsMember={user.groups}
        closeModal={() => setIsOpenModal(false)}
      />
    </Flex>
  );
}

import { useState } from "react";

export function usePaginateCursor() {
  const [direction, setDirection] = useState<"next" | "prev">("next");
  const [page, setPage] = useState<string | null>(null);

  const setNextPage = (nextPage: string) => {
    setPage(nextPage);
    setDirection("next");
  };

  const setPreviousPage = (previousPage: string) => {
    setPage(previousPage);
    setDirection("prev");
  };

  function DefaultValueCursorReset() {
    setPage(null);
    setDirection("next");
  }

  return {
    page,
    direction,
    setNextPage,
    setPreviousPage,
    DefaultValueCursorReset,
  };
}

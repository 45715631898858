import { Box, Button } from "@grupoboticario/flora-react";

interface PaginateProps {
  meta: Meta;
  nextPageDisabled?: boolean;
  previousPageDisabled?: boolean;
}

export interface Meta {
  previous?: () => void;
  next?: () => void;
}

export function Paginate({
  meta,
  previousPageDisabled = true,
  nextPageDisabled = false,
}: PaginateProps) {
  return (
    <Box
      data-testid="paginate"
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "$3",
        width: "100%",
      }}
    >
      <Button
        onClick={() => meta.previous()}
        hierarchy="secondary"
        styleSemantic="neutral"
        disabled={previousPageDisabled}
      >
        {"Página anterior"}
      </Button>
      <Button
        onClick={() => meta.next()}
        hierarchy="secondary"
        styleSemantic="neutral"
        disabled={nextPageDisabled}
      >
        {"Próxima página"}
      </Button>
    </Box>
  );
}

import { useTeamsSelectList } from "@features/TeamMembers/Hooks";
import {
  Box,
  Button,
  Dropdown,
  DropdownItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Modal as ModalFlora,
  ModalFooter,
  ModalHeader,
  Toaster,
} from "@grupoboticario/flora-react";
import { CheckCircleIcon } from "@grupoboticario/flora-react-icons";
import { Team } from "@shared/types";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAddUserToTeam } from "./useAddUserToTeam";

interface AddTeamModalProps {
  title: string;
  isOpen: boolean;
  teamsUserIsMember: Team[];
  closeModal: () => void;
}

export function AddTeamModal({
  title,
  isOpen,
  teamsUserIsMember = [],
  closeModal,
}: AddTeamModalProps) {
  const { userId } = useParams();
  const { data: teams } = useTeamsSelectList();
  const [teamList, setTeamList] = useState<Team[]>([]);

  const [selectedTeam, setSelectedTeam] = useState<Team>(null);
  const { mutate: addUserToTeam, isPending } = useAddUserToTeam();

  function handleSelectNewTeamChange(event: ChangeEvent<HTMLInputElement>) {
    const teamId = event.target.value;

    const team = teamList.find((teamData) => teamData.id === teamId);

    setSelectedTeam(team);
  }

  function clearTeamsDropdown() {
    setSelectedTeam(null);
  }

  function handleAddUserToTeam() {
    if (!selectedTeam) return;
    if (!userId) return;

    addUserToTeam(
      {
        team: selectedTeam,
        userId,
      },
      {
        onSuccess() {
          clearTeamsDropdown();
          closeModal();

          Toaster.notify({
            kind: "success",
            description: `O usuário foi adicionado ao time!`,
            origin: "right-top",
            Icon: <CheckCircleIcon />,
          });
        },
        onError() {
          Toaster.notify({
            kind: "error",
            description: "Erro ao adicionar o usuário ao time",
            origin: "right-top",
          });
        },
      },
    );
  }

  useEffect(() => {
    function removeTeamsUserIsMemberFromList() {
      if (teamsUserIsMember.length === 0) {
        setTeamList(teams);
        return;
      }

      if (teams && teams.length > 0) {
        const teamsWithoutUserIsMember = teams.filter((team) => {
          const isMember = teamsUserIsMember.find(
            (teamUserIsMember) => teamUserIsMember.id === team.id,
          );

          return !isMember;
        });

        setTeamList(teamsWithoutUserIsMember);
      }
    }

    removeTeamsUserIsMemberFromList();
  }, [teams, teamsUserIsMember]);

  return (
    <ModalFlora
      css={{
        background: "$backgroundPrimary",
        border: "solid $nonInteractiveAltOutline",
        boxShadow: "$centerMid",
      }}
      open={isOpen}
      onOpenChange={closeModal}
    >
      <ModalContent
        size="standard"
        css={{ padding: "$8", borderRadius: "$medium" }}
      >
        <ModalCloseButton />
        <ModalHeader
          css={{
            fontSize: "$subtitleDesk",
            fontWeight: "$regular",
            lineHeight: "$subtitleDesk",
            color: "$nonInteractivePredominant",
          }}
        >
          {title}
        </ModalHeader>
        <ModalBody>
          <Box>
            <Dropdown
              id={"teams"}
              labelText="Equipe"
              optionalText=""
              as="form"
              required
              invalid={!selectedTeam}
              invalidText="Campo obrigatório"
              value={selectedTeam?.id}
              onChange={handleSelectNewTeamChange}
            >
              {teamList?.map((teamData) => (
                <DropdownItem key={teamData.id} value={teamData.id}>
                  {teamData.name}
                </DropdownItem>
              ))}
            </Dropdown>
          </Box>
        </ModalBody>
        <ModalFooter
          css={{ display: "flex", flexDirection: "column", gap: "$3" }}
        >
          <Button
            disabled={!selectedTeam}
            onClick={handleAddUserToTeam}
            isLoading={isPending}
          >
            Adicionar
          </Button>
          <ModalCloseButton asChild>
            <Button hierarchy={"secondary"} styleSemantic={"neutral"}>
              Cancelar
            </Button>
          </ModalCloseButton>
        </ModalFooter>
      </ModalContent>
    </ModalFlora>
  );
}

import { ReactNode } from "react";
import { Spinner } from "@grupoboticario/flora-react";

import { FeedbackContainer } from "./styles";

interface RootProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  isLoading?: boolean;
}
export function Root({ children, isLoading = false, ...rest }: RootProps) {
  return (
    <FeedbackContainer {...rest}>
      {children}
      {isLoading && <Spinner size={"md"} color="$linkDefault" />}
    </FeedbackContainer>
  );
}

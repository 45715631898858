import { createContext, useContext, useState, ReactNode } from "react";

interface SearchContextData {
  searchTerm: string;
  setSearchTerm: (query: string) => void;
}

interface SearchContextProps {
  children: ReactNode;
}

const SearchContext = createContext<SearchContextData>({} as SearchContextData);

export function SearchProvider({ children }: SearchContextProps) {
  const [searchTerm, setSearchTerm] = useState<string>("");

  return (
    <SearchContext.Provider value={{ searchTerm, setSearchTerm }}>
      {children}
    </SearchContext.Provider>
  );
}

export function useSearch() {
  return useContext(SearchContext);
}

import { styled } from "@grupoboticario/flora-react";
import { Table as TableFT } from "@grupoboticario/flora-team-react-table";

export const TableRoot = styled(TableFT.Root, {
  borderSpacing: "unset",
  borderCollapse: "unset",
  "-webkit-user-select": "none" /* Safari */,
  "user-select": "none",

  "& tr > td:first-of-type": {
    borderRadius: "unset",
  },

  "& tr > td:last-of-type": {
    borderRadius: "unset",
  },

  "& th, td": {
    background: "unset",
    borderBottom: "1px solid $nonInteractiveOutline",
    padding: "0 $6",
  },

  "& th:nth-last-child(-n+2)": {
    width: "$16",
  },

  "& th": {
    height: "$16",
    verticalAlign: "middle",
  },

  "& td": {
    height: "$14",
  },

  "& > thead": {
    position: "sticky",
    top: 0,
    background: "$backgroundSecondary",
    zIndex: 1,
  },

  "& > tbody tr:nth-child(odd)": {
    background: "$backgroundPrimary",
  },
}) as typeof TableFT.Root;

export const TableHead = styled(TableFT.Header, {
  background: "$backgroundSecondary",
  span: {
    fontSize: "$bodySmall",
    fontWeight: "$medium",
    textTransform: "capitalize",
  },

  "&:last-child": {
    textAlign: "end",
  },

  "@mobile": {
    top: 0,
  },
}) as typeof TableFT.Header;

export const TableCell = styled(TableFT.Cell, {
  overflow: "hidden",
  "& > span": {
    fontSize: "$bodySmall",
    fontWeight: "$medium",
  },
}) as typeof TableFT.Cell;

import { Flex, Box, Tag } from "@grupoboticario/flora-react";
import { AttributeValue } from "../Details/Details.styles";
import { Struct } from "../Details/userDetails.types";

interface SectorsProps {
  struct: Struct;
}
export function Sectors({ struct }: SectorsProps) {
  return (
    <Flex direction={"column"} css={{ paddingBottom: "$6" }}>
      {!struct || !struct.sectors || struct.sectors.length === 0 ? (
        <Box
          css={{
            padding: "$0 $4",
          }}
        >
          <AttributeValue>
            Não há setores que este usuário herdou ou que foram atribuídos a
            ele.
          </AttributeValue>
        </Box>
      ) : (
        <Flex align={"center"} gap={"$2"} wrap={"wrap"}>
          {struct.sectors?.map((sector) => (
            <Tag
              key={sector.id}
              variant="assorted9"
              css={{
                fontWeight: "$medium",
                backgroundColor: "$actionableDefault",
              }}
            >
              {sector.branch}
            </Tag>
          ))}
        </Flex>
      )}
    </Flex>
  );
}

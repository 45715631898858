import { Route, Routes } from "react-router-dom";
import { Team } from "./pages/Team";
import { Teams } from "./pages/Teams";
import { NewMember } from "./pages/NewMember";
import { Users } from "./pages/Users";
import { User } from "./pages/User";
import { SearchProvider } from "@shared/components/Search/useSearch";

export function Router() {
  return (
    <Routes>
      <Route path="/">
        <Route path="equipes">
          <Route index element={<Teams />} />
          <Route path=":teamId">
            <Route index element={<Team />} />
            <Route path="integrantes">
              <Route
                path="cadastrar"
                element={
                  <SearchProvider>
                    <NewMember />
                  </SearchProvider>
                }
              />
            </Route>
          </Route>
        </Route>
        <Route path="usuarios">
          <Route index element={<Users />} />
          <Route path=":userId" element={<User />} />
        </Route>
      </Route>
    </Routes>
  );
}

import { authService } from "@vdi/auth-service";

export async function removeTeamMember(teamId: string, memberId: string) {
  const { token } = await authService.getTokenOrRedirect();
  const url = `${process.env.API_BASE_URL}/groups/${teamId}/users/${memberId}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    method: "DELETE",
  });

  if (!response.ok) {
    throw new Error(`request response status error: ${response.status}`);
  }
}

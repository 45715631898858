import { useMutation } from "@tanstack/react-query";
import { MoveMembersMutation } from "../Components/Modal";
import { moveMembersToGroup } from "@api/TeamMembers";
import { queryClient } from "../../../services/reactQuery";

export function useMoveMembersToGroup(
  teamId: string,
  moveMembers: MoveMembersMutation,
) {
  return useMutation({
    mutationFn: () => moveMembersToGroup(teamId, moveMembers),
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ["team", teamId, "members"],
          refetchType: "all",
        });
      }, 3000);
    },
  });
}

import { Flex, styled } from "@grupoboticario/flora-react";

export const TeamContainer = styled(Flex, {
  flexDirection: "column",
  justifyContent: "flex-start",
  height: "100%",
  marginTop: "$6",
  padding: "$6",
  gap: "$6",
  borderRadius: "$medium",
  borderWidth: "$hairline",
  backgroundColor: "$backgroundPrimary",
});

export const PageTitle = styled(Flex, {
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "$2",
  fontSize: "$subtitleDesk",
  fontWeight: "$medium",
});
